import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { FormattedMessage } from 'react-intl';

import BlockRouter from '../components/BlockRouter';
import GradientWrapper from '../components/GradientWrapper';
import HelmetMichelberger from '../components/HelmetMichelberger';
import Information from '../components/Information';
import Layout from '../components/Layout';
import OtherEventRoomTeaser from '../components/OtherEventRoomTeaser';
import PageTitle from '../components/PageTitle';
import PageDescription from '../components/PageDescription';

import { SuggestedRoomsHeading } from './room';
import { RoomList } from './rooms';


const EventRoom = ({ data, location, pageContext }) => {
  const { languageSwitch } = pageContext;
  const footerData = data.allDatoCmsFooter.edges[0].node;
  const eventRoomsPage = data.allDatoCmsEventRoomsPage.edges[0].node;
  const {
    body, description, gradient, infoButtonLabel, infoButtonUrl, infoText, name,
    otherRooms, seoMetaTags,
  } = data.allDatoCmsEventRoom.edges[0].node;

  return (
    <Layout languageSwitch={languageSwitch} location={location}>
      <GradientWrapper footerData={footerData} gradient={gradient} languageSwitch={languageSwitch}>
        <HelmetMichelberger seo={seoMetaTags} />
        <PageTitle>{name}</PageTitle>
        <PageDescription isSmall>{description}</PageDescription>
        <Information buttonLabel={infoButtonLabel} buttonUrl={infoButtonUrl} text={infoText} />
        <For each="block" index="index" of={body}>
          <BlockRouter key={block.id} block={block} index={index} />
        </For>
        <If condition={otherRooms.length}>
          <SuggestedRoomsHeading>
            <FormattedMessage defaultMessage="Other Event Spaces" id="global.otherRoomsTitle" />
          </SuggestedRoomsHeading>
        </If>
        <RoomList>
          <For each="room" index="index" of={otherRooms}>
            <OtherEventRoomTeaser
              key={`room_${room.id}`}
              room={room}
              roomPath={`/${eventRoomsPage.slug}/${room.slug}`}
            />
          </For>
        </RoomList>
      </GradientWrapper>
    </Layout>
  );
};

EventRoom.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export default EventRoom;

export const query = graphql`
  query EventRoomBySlug($locale: String!, $slug: String!) {
    allDatoCmsEventRoom(filter: { slug: { eq: $slug }, locale: { eq: $locale } }) {
      edges {
        node {
          name
          slug
          description
          infoButtonLabel
          infoButtonUrl
          infoText
          ...GatsbyDatoCmsEventRoomBody
          seoMetaTags {
            ...GatsbyDatoCmsSeoMetaTags
          }
          gradient {
            color {
              hex
            }
          }
          otherRooms {
            id
            name
            slug
            featuredImage {
              fluid(maxWidth: 2400, maxHeight: 1600, imgixParams: { fm:"jpg", auto:"format" }) {
                ...GatsbyDatoCmsFluid
              }
            }
          }
        }
      }
    }
    allDatoCmsEventRoomsPage(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          slug
        }
      }
    }
    allDatoCmsFooter(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          ...footerFields
        }
      }
    }
  }
`;
