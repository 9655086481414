import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { fontStyles } from '../constants/styles';
import { fluidPropTypes } from '../types';

import LocalisedLink from './LocalisedLink';
import MichelbergerImage from './MichelbergerImage';
import { Room } from './RoomTeaser';


const RoomDescription = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 1.25rem;
  ${fontStyles.body}

  a:last-child {
    ${fontStyles.bodySmall}
  }
`;

const OtherEventRoomTeaser = ({ room, roomPath }) => (
  <Room>
    <LocalisedLink hasUnderline={false} to={roomPath}>
      <MichelbergerImage image={room.featuredImage} />
    </LocalisedLink>
    <RoomDescription>
      <LocalisedLink hasUnderline={false} to={roomPath}>{room.name}</LocalisedLink>
      <LocalisedLink to={roomPath}>
        <FormattedMessage defaultMessage="View the Space" id="global.viewEventSpace" />
      </LocalisedLink>
    </RoomDescription>
  </Room>
);


OtherEventRoomTeaser.propTypes = {
  room: PropTypes.shape({
    featuredImage: PropTypes.shape({
      id: PropTypes.string,
      fluid: fluidPropTypes,
    }).isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  roomPath: PropTypes.string.isRequired,
};

export default OtherEventRoomTeaser;
